import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { HelpStore } from 'src/app/help/state/help.store';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { CompanyGroup } from '../../company-group/state/company-group.model';
import { CompanyBranch } from '../../setting/models/CompanyBranch';
import { EmployeeForStructureTree } from '../interface/employee-for-structure-tree.interface';
import { AttendanceApprovers } from '../models/AttendanceApprovers';
import { Employee } from '../models/Employee';
import { EmployeeActivity } from '../models/EmployeeActivity';
import { EmployeeContactPerson } from '../models/EmployeeContactPerson';
import { EmployeePersonalData } from '../models/EmployeePersonalData';
import { EmployeeRequiredDataUpdates } from '../models/EmployeeRequiredDataUpdates';
import { EmployeeSignature } from '../models/EmployeeSignature';
import { EmployeeStats } from '../models/EmployeeStats';
import { File } from '../models/File';
import { EmployeeListType } from '../types';

interface Structure {
    children: Array<Employee>;
    parents: Array<Employee>;
    siblings: Array<Employee>;
}

interface Termination {
    termination_description: string;
    termination_end_date: string;
    termination_reason: string;
    cancel: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _helpStore: HelpStore,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper,
    ) {
    }

    public archiveEmployee(employeeID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>(`/api/employees/${employeeID}/archive`, {})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public getAllCompanyGroups(): Observable<Array<CompanyGroup>> {
        return this._http.get<Array<CompanyGroup>>('/api/company-groups');
    }

    public getSavedSignatures(employeeID: number, latest = true): Observable<Array<EmployeeSignature>> {
        return this._http.get<Array<EmployeeSignature>>(`/api/employees/${employeeID}/signatures/get-saved${latest ? '?latest' : ''}`);
    }

    public getAllCompanyBranches(scope: string, employeeID?: number): Observable<Array<CompanyBranch>> {
        let params = new HttpParams().set('scope', scope);
        if (employeeID) {
            params = params.set('employeeID', employeeID.toString());
        }

        return this._http.get<Array<any>>('/api/company-branches', {params});
    }

    public getAllEmployeePositionTypes(): Observable<Array<any>> {
        return this._http.get<Array<any>>('/api/employees/position-types');
    }

    public getPersonalData(employeeID: number): Observable<EmployeePersonalData> {
        return this._http.get<EmployeePersonalData>(`/api/employees/${employeeID}/personal-data?widget=PERSONAL_DATA`);
    }

    public deleteActivity(employeeID: number, employeeActivityID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete('/api/employees/' + employeeID + '/activities/' + employeeActivityID)
                    .subscribe(
                        (response: any) => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public deleteContactPerson(employeeID: number, employeeContactPersonID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete('/api/employees/' + employeeID + '/contact-persons/' + employeeContactPersonID)
                    .subscribe(
                        (response: any) => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public deleteEmployee(employeeID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise((resolve, reject) => {
            this._http.delete<ApiResponse>(`/api/employees/${employeeID}`)
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);

                        reject();
                    }
                );
        });
    }

    public getAllEmployees(archived?: boolean, style?: string, byPermission?: string, byRole?: string, structure?: string): Observable<Array<Employee>> {
        let params = new HttpParams();
        if (archived) {
            params = params.set('archived', '1');
        }
        if (style) {
            params = params.set('style', style);
        }
        if (byPermission) {
            params = params.set('by_permission', byPermission);
        }
        if (byRole) {
            params = params.set('by_role', byRole);
        }
        if (structure) {
            params = params.set('structure', structure);
        }

        return this._http.get<Array<Employee>>('/api/employees', {params});
    }

    public getAllFilesByActivity(activity: EmployeeActivity): Observable<Array<File>> {
        return this._http.get<Array<File>>('/api/employees/' + activity.employee_ID + '/activities/' + activity.employee_activity_ID + '/files');
    }

    public getContactPersonsByEmployeeID(employeeID: number): Observable<Array<EmployeeContactPerson>> {
        return this._http.get<Array<EmployeeContactPerson>>('/api/employees/' + employeeID + '/contact-persons');
    }

    public getAttendaceApproversByEmployee(employeeID: number): Observable<AttendanceApprovers> {
        return this._http.get<AttendanceApprovers>('/api/employees/' + employeeID + '/datetimes/approvers');
    }

    public getEmployeeFilesByEmployeeID(employeeID: number): Observable<Array<File>> {
        return this._http.get<Array<File>>('/api/employees/' + employeeID + '/files');
    }

    public getEmployeeInfoByEmployeeID(employeeID: number, style: string, withCustomFields = false): Observable<Employee> {
        let params = new HttpParams();

        if (style) {
            params = params.set('style', style);
        }

        if (withCustomFields) {
            params = params.set('widget', 'EMPLOYEE');
        }

        return this._http.get<Employee>('/api/employees/' + employeeID, {params});
    }

    public getEmployeePayrollsByEmployeeID(employeeID: number): Observable<Array<File>> {
        return this._http.get<Array<File>>('/api/employees/' + employeeID + '/payrolls');
    }

    public getEmployeeStatsByEmployeeID(employeeID: number): Observable<EmployeeStats> {
        return this._http.get<EmployeeStats>('/api/employees/' + employeeID + '/stats');
    }

    public getEmployeesList(params: ListParameters<Employee>, type: EmployeeListType, filterParameters?: {}): Observable<ListResponse<Employee>> {
        const p = transformParameters(params);

        return this._http.post<ListResponse<Employee>>('/api/employees/list', {
            ...p,
            ...filterParameters,
            ...{type}
        });
    }

    public checkPropertyExistence(name: string, value: string): Promise<any> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>(`/api/employees/check-property-existence`, {name, value})
                .subscribe(
                    response => {

                        resolve(response);
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        });
    }

    public getActivitiesByEmployeeID(employeeID: number): Observable<Array<EmployeeActivity>> {
        return this._http.get<Array<EmployeeActivity>>('/api/employees/' + employeeID + '/activities');
    }

    public getStructure(employeeID: number): Observable<Structure> {
        return this._http.get<Structure>('/api/employees/' + employeeID + '/structure');
    }

    public getStructureTree(options?: { employeeID?: number; groupId?: number; showArchived?: boolean; flat?: boolean }): Observable<Array<EmployeeForStructureTree>> {
        let params = null;

        if (options?.employeeID) {
            params = new HttpParams()
                .set('employeeID', String(options.employeeID));
        } else if (options?.groupId) {
            params = new HttpParams()
                .set('companyGroupID', String(options.groupId));
        }

        if (options?.showArchived) {
            if (params) {
                params = params.append('archived', String(true));
            } else {
                params = new HttpParams()
                    .set('archived', String(true));
            }
        }
        if (!options?.showArchived) {
            if (params) {
                params = params.append('archived', String(false));
            } else {
                params = new HttpParams()
                    .set('archived', String(false));
            }
        }

        if (options?.flat) {
            if (params) {
                params = params.append('flat', String(true));
            } else {
                params = new HttpParams()
                    .set('flat', String(true));
            }
        }

        return this._http.get<Array<EmployeeForStructureTree>>('/api/employees/structure-tree', {params});
    }

    public saveActivity(activity: EmployeeActivity, employeeID: number, employeeActivityID?: number): Promise<'done'> {
        return new Promise(resolve => {
            if (employeeActivityID) {
                this._http.post<ApiResponse>('/api/employees/' + employeeID + '/activities/' + employeeActivityID, activity)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                this._http.post<ApiResponse>('/api/employees/' + employeeID + '/activities', activity)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            }
        });
    }

    public saveAvatar(avatar: string, employeeID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/change-avatar', {avatar})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public setApprovers(employeeID: number, approvers: Array<number>, method: string, autoApprove = false): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/datetimes/approvers', {approvers, method, auto_approve: autoApprove})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveContactPerson(form: EmployeeContactPerson, employeeID: number, employeeContactPersonID?: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            if (employeeContactPersonID) {
                this._http.post<ApiResponse>('/api/employees/' + employeeID + '/contact-persons/' + employeeContactPersonID, form)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stop();
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stop();
                        }
                    );
            } else {
                this._http.post<ApiResponse>('/api/employees/' + employeeID + '/contact-persons', form)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stop();
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stop();
                        }
                    );
            }
        });
    }

    public saveEmployeeComplex(form: any): Promise<'done'> {
        return new Promise(resolve => {
            this._loaderService.start();

            this._http.post<ApiResponse>('/api/employees/save', form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {

                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveEmployee(form: Employee, employeeID?: number): Promise<'done'> {
        return new Promise(resolve => {
            this._loaderService.start();

            if (employeeID) {
                this._http.post<ApiResponse>('/api/employees/' + employeeID, form)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stop();
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stop();
                        }
                    );
            } else {
                this._http.post<ApiResponse>('/api/employees', form)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stop();
                        },
                        error => {

                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stop();
                        }
                    );
            }
        });
    }

    public savePasswordByEmployeeID(password: string, passwordConfirmation: string, employeeID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/change-password', {password, password_confirmation: passwordConfirmation})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public savePersonalData(personalData: EmployeePersonalData, employeeID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/personal-data', personalData)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveSalutation(salutation: string, employeeID: number): Promise<'done'> {
        this._loaderService.start();
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/change-salutation', {salutation})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveSignature(employeeID: number, signature: string): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>(`/api/employees/${employeeID}/set-signature`, {signature})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public setHelpMode(helpMode: boolean, employeeID: number): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/set-help-mode', {help_mode: helpMode})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._helpStore.update({helpMode});

                        resolve('done');
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        });
    }

    public setLocale(locale: string, employeeID: number): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/set-locale', {locale})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._helpStore.update({locale});

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public setRole(role: string, employeeID: number): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/settings/change-role', {role})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._helpStore.update({role});

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public setBranch(branch: string, employeeID: number): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/settings/change-branch', {branch})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._helpStore.update({branch});

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public setActiveUser(activeUser: boolean, employeeID: number): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/settings/change-active-user', {active_user: activeUser})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._helpStore.update({activeUser});

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public terminateEmployee(form: Termination, employeeID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/terminate', form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public unArchiveEmployee(employeeID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>(`/api/employees/${employeeID}/unarchive`, {})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public getEmployeeSocialLinks(employeeID: number) {
        return this._http.get('/api/employees/' + employeeID + '/social-networks');
    }

    public getRequiredDataUpdates(employeeID: number): Observable<EmployeeRequiredDataUpdates> {
        return this._http.get<EmployeeRequiredDataUpdates>('/api/employees/' + employeeID + '/get-required-data-updates');
    }

    public saveEmployeeSocialLink(employeeID: number, data): Promise<'done'> {
        this._loaderService.start();
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/social-networks', data)
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public getEmployeeTypes(): Observable<Array<string>> {
        return this._http.get<Array<string>>('/api/employees/types');
    }

    public saveEmployeeType(form: any, employeeID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const isConfirmed = await this._swalHelper.showConfirmChangeDialog();

            if (isConfirmed) {
                this._http.post('/api/employees/' + employeeID + '/change-type', form)
                    .subscribe(
                        (response: any) => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public forceEmployeeToUpdateData(data: object, employeeID: number): Promise<'done'> {
        this._loaderService.start();
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/force-employee-to-update-data', {data})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveSupervisor(employeeID: number, supervisorId: number, descendantsAction: { type: string, new_supervisor: number }): Promise<'done'> {
        this._loaderService.start();
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/change-supervisor', {supervisor_ID: supervisorId, descendants_action: descendantsAction})
                .subscribe(
                    response => {
                        this._loaderService.stop();
                        this._apiHelper.handleSuccessResponse(response);
                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);
                        this._loaderService.stop();
                    }
                );
        });
    }

    public syncStructureByTeams(): Promise<'done'> {
        this._loaderService.start();
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/sync-structure-by-teams', {})
                .subscribe(
                    response => {
                        this._loaderService.stop();
                        this._apiHelper.handleSuccessResponse(response);
                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);
                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveMeta(employeeID: number, key: string, value: string, modalRef: NgbActiveModal = null, successCallback: () => void = null): void {
        this._loaderService.start();
        this._http.post<ApiResponse>(`/api/employees/${employeeID}/save-meta`, {key, value})
            .subscribe(
                response => {
                    this._loaderService.stop();
                    this._apiHelper.handleSuccessResponse(response);
                    if (successCallback) {
                        successCallback();
                    }
                    if (modalRef) {
                        modalRef.close();
                    }
                },
                error => {
                    this._loaderService.stop();
                    this._apiHelper.handleErrorResponse(error);
                }
            );
    }

    public setSignatureCertificate(formData: FormData, employeeID: number): Promise<'done'> {
        this._loaderService.start();
        return new Promise(resolve => {
            this._http.post<ApiResponse>(`/api/employees/${employeeID}/signature-certificate`, formData)
                .subscribe(
                    response => {
                        this._loaderService.stop();
                        this._apiHelper.handleSuccessResponse(response);
                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);
                        this._loaderService.stop();
                    }
                );
        });
    }

    public deleteCertificate(employeeID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete(`/api/employees/${employeeID}/signature-certificate`)
                    .subscribe(
                        (response: any) => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public toggleEmployeeActiveAccess(employeeID: number, form: any): Promise<'done'> {
        this._loaderService.start();
        return new Promise(resolve => {
            this._http.post<ApiResponse>(`/api/employees/${employeeID}/toggle-active`, form)
                .subscribe(
                    response => {
                        this._loaderService.stop();
                        this._apiHelper.handleSuccessResponse(response);
                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();
                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public resetEmployeePassword(employeeID: number): void {
        this._loaderService.start();
        this._http.get<ApiResponse>(`/api/employees/${employeeID}/reset-password`)
            .subscribe(response => {
                this._loaderService.stop();
                this._apiHelper.handleSuccessResponse(response);
            }, (error) => {
                this._loaderService.stop();
                this._apiHelper.handleErrorResponse(error);
            });
    }
}
