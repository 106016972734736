import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule, LOCATION_INITIALIZED, NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DataTablesModule } from 'angular-datatables';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxCurrencyDirective, NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { NgxEchartsDirective } from 'ngx-echarts';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSliderModule } from 'ngx-slider-v2';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WebcamModule } from 'ngx-webcam';
import { LANG_KEY } from '../core/constants';
import { UIDataTableModule, UIItemsPerPageSelectModule, UIPaginationModule, UIRowLoaderModule } from '../ui';
import { ApiHelper } from './common/ApiHelper';
import { DataTablesHelper } from './common/DataTablesHelper';
import { FlatpickrHelper } from './common/FlatpickrHelper';
import { FormatHelper } from './common/FormatHelper';
import { FormHelper } from './common/FormHelper';
import { SummernoteHelper } from './common/SummernoteHelper';
import { SwalHelper } from './common/SwalHelper';
import { AccountCategoriesPeriodsComponent } from './components/partials/account-categories-periods/account-categories-periods.component';
import { ActivityLogDetailModalComponent } from './components/partials/activity-log-detail-modal/activity-log-detail-modal.component';
import { ActivityLogListComponent } from './components/partials/activity-log-list/activity-log-list.component';
import { AiSummaryGeneratorComponent } from './components/partials/ai-helper-summary-generator/ai-summary-generator.component';
import { AiHelperComponent } from './components/partials/ai-helper/ai-helper.component';
import { AssignPrivilegesComponent } from './components/partials/assign-privileges/assign-privileges.component';
import { BarChartComponent } from './components/partials/bar-chart/bar-chart.component';
import { BarEChartComponent } from './components/partials/bar-echart/bar-echart.component';
import { CameraModalComponent } from './components/partials/camera-modal/camera-modal.component';
import { CheckExtraVerificationModalComponent } from './components/partials/check-extra-verification-modal/check-extra-verification-modal.component';
import { CommentFormComponent } from './components/partials/comment-form/comment-form.component';
import { CommentListComponent } from './components/partials/comment-list/comment-list.component';
import { CommentComponent } from './components/partials/comment/comment.component';
import { CustomFieldsFilterComponent } from './components/partials/custom-fields-filter/custom-fields-filter.component';
import { CustomFieldsComponent } from './components/partials/custom-fields/custom-fields.component';
import { D3OrgChartComponent } from './components/partials/d3-org-chart/d3-org-chart.component';
import { EmployeeBoxAvatarComponent } from './components/partials/employee-box-avatar/employee-box-avatar.component';
import { ExportComponent } from './components/partials/export/export.component';
import { FileTypeComponent } from './components/partials/file-type/file-type.component';
import { FilesTableComponent } from './components/partials/files-table/files-table.component';
import { GanttChartComponent } from './components/partials/gantt-chart/gantt-chart.component';
import { HelpComponent } from './components/partials/help/help.component';
import { LineChartComponent } from './components/partials/line-chart/line-chart.component';
import { ModalLoaderComponent } from './components/partials/modal-loader/modal-loader.component';
import { MultiFilesPreviewModalComponent } from './components/partials/multi-files-preview-modal/multi-files-preview-modal.component';
import { PieChartComponent } from './components/partials/pie-chart/pie-chart.component';
import { PlanChartComponent } from './components/partials/plan-chart/plan-chart.component';
import { RadarChartComponent } from './components/partials/radar-chart/radar-chart.component';
import { RadialChartComponent } from './components/partials/radial-chart/radial-chart.component';
import { SearchComponent } from './components/partials/search/search.component';
import { StackedBarChartComponent } from './components/partials/stacked-bar-chart/stacked-bar-chart.component';
import { SwitchWorkspaceModalComponent } from './components/partials/switch-workspace-modal/switch-workspace-modal.component';
import { TargetableComponent } from './components/partials/targetable/targetable.component';
import { TreeStructureComponent } from './components/partials/tree-structure/tree-structure.component';
import { UserInactivityModalComponent } from './components/partials/user-inactivity-modal/user-inactivity-modal.component';
import { FilterChangedDirective } from './filter-changed-directive';
import { FormHelperDirective } from './form-helper-directive';
import { localStorageSafe } from './functions';
import { AuthInterceptor } from './Interceptors/AuthInterceptor';
import { DocumentExportInterceptor } from './Interceptors/DocumentExportInterceptor';
import { ExtraVerificationInterceptor } from './Interceptors/ExtraVerificationInterceptor';
import { SubmitButtonInterceptor } from './Interceptors/SubmitButtonInterceptor';
import { LocalizedCurrencyPipeModule } from './localized-currency-pipe.module';
import { OnlyNumberDirective } from './only-numbers-input.directive';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SelectAllDirective } from './select-all-directive';
import { SubmitButtonDirective } from './submit-button-directive';

export const customCurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    allowZero: true,
    decimal: ',',
    precision: 2,
    prefix: '',
    suffix: '',
    thousands: ' ',
    nullable: true,
    min: null,
    max: null,
    inputMode: NgxCurrencyInputMode.Natural
};

export function appInitializerFactory(translate: TranslateService, loaderService: NgxUiLoaderService, injector: Injector) {
    return () => new Promise<any>((resolve: any) => {
        loaderService.startLoader('logo');
        const urlParams = new URLSearchParams(window.location.search);
        const localeURL = urlParams.get('locale');
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

        locationInitialized.then(() => {
            const langFromLocalStorage = localStorageSafe.getItem(LANG_KEY);
            let langToSet = localeURL || langFromLocalStorage || translate.getBrowserLang() || 'default';

            if (langToSet !== 'en' && langToSet !== 'cs' && langToSet !== 'sk') {
                langToSet = 'cs';
            }

            localStorageSafe.setItem(LANG_KEY, langToSet);
            translate.setDefaultLang(langToSet);
            loaderService.stopLoader('logo');

            resolve(null);
        });
    });
}

@NgModule({
    declarations: [
        AccountCategoriesPeriodsComponent,
        AssignPrivilegesComponent,
        BarChartComponent,
        CameraModalComponent,
        CommentComponent,
        CommentFormComponent,
        CommentListComponent,
        FileTypeComponent,
        FilesTableComponent,
        FormatTimePipe,
        GanttChartComponent,
        HelpComponent,
        LineChartComponent,
        PieChartComponent,
        RadialChartComponent,
        SafeHtmlPipe,
        SearchComponent,
        StackedBarChartComponent,
        SwitchWorkspaceModalComponent,
        TreeStructureComponent,
        EmployeeBoxAvatarComponent,
        RadarChartComponent,
        D3OrgChartComponent,
        CheckExtraVerificationModalComponent,
        OnlyNumberDirective,
        PlanChartComponent,
        UserInactivityModalComponent,
        ExportComponent,
        CustomFieldsComponent,
        SelectAllDirective,
        FormHelperDirective,
        SubmitButtonDirective,
        ActivityLogListComponent,
        ActivityLogDetailModalComponent,
        FilterChangedDirective,
        AiHelperComponent,
        TargetableComponent,
        ModalLoaderComponent,
        CustomFieldsFilterComponent,
        AiSummaryGeneratorComponent,
        MultiFilesPreviewModalComponent,
        BarEChartComponent
    ],
    imports: [
        CdkTreeModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        NgbModule,
        NgApexchartsModule,
        DataTablesModule,
        NgSelectModule,
        ContentLoaderModule,
        NgxSliderModule,
        WebcamModule,
        FlatpickrModule.forRoot(),
        TranslateModule,
        UIRowLoaderModule,
        LocalizedCurrencyPipeModule,
        NgxSkeletonLoaderModule,
        NgOptimizedImage,
        NgxCurrencyDirective,
        PdfViewerModule,
        NgxSummernoteModule,
        UIDataTableModule,
        UIItemsPerPageSelectModule,
        UIPaginationModule,
        NgxEchartsDirective
    ],
    providers: [
        FormatHelper,
        DataTablesHelper,
        ApiHelper,
        SwalHelper,
        FlatpickrHelper,
        FormHelper,
        SummernoteHelper,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ExtraVerificationInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: DocumentExportInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: SubmitButtonInterceptor, multi: true},
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, NgxUiLoaderService, Injector],
            multi: true
        },
        provideEnvironmentNgxCurrency(customCurrencyMaskConfig),
    ],
    exports: [
        AccountCategoriesPeriodsComponent,
        AssignPrivilegesComponent,
        BarChartComponent,
        CameraModalComponent,
        CommentComponent,
        CommentFormComponent,
        CommentListComponent,
        FileTypeComponent,
        FilesTableComponent,
        GanttChartComponent,
        HelpComponent,
        LineChartComponent,
        PieChartComponent,
        RadialChartComponent,
        StackedBarChartComponent,
        SwitchWorkspaceModalComponent,
        TreeStructureComponent,
        RadarChartComponent,
        FormatTimePipe,
        SafeHtmlPipe,
        CommonModule,
        ContentLoaderModule,
        DataTablesModule,
        FlatpickrModule,
        FormsModule,
        HttpClientModule,
        NgApexchartsModule,
        NgSelectModule,
        NgbModule,
        NgxSliderModule,
        NgxCurrencyDirective,
        NgxSummernoteModule,
        ReactiveFormsModule,
        TranslateModule,
        WebcamModule,
        LocalizedCurrencyPipeModule,
        EmployeeBoxAvatarComponent,
        D3OrgChartComponent,
        OnlyNumberDirective,
        PlanChartComponent,
        ExportComponent,
        CustomFieldsComponent,
        SelectAllDirective,
        FormHelperDirective,
        SearchComponent,
        SubmitButtonDirective,
        ActivityLogListComponent,
        FilterChangedDirective,
        AiHelperComponent,
        TargetableComponent,
        ModalLoaderComponent,
        CustomFieldsFilterComponent,
        AiSummaryGeneratorComponent,
        MultiFilesPreviewModalComponent,
        BarEChartComponent
    ]
})
export class SharedModule {
}
