import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/setting/services/company.service';
import { Employee } from '../../../../employee/models/Employee';
import { EmployeeService } from '../../../../employee/services/employee.service';
import { EmployeeDatetimeType } from '../../../models/EmployeeDatetimeType';
import { DatetimesTypesStoreService } from '../../../services/datetimes-types-store.service';

const DEFAULT_DISPLAY_MODE = 'WHOLE_COMPANY';

@Component({
    selector: 'app-attendance-settings',
    templateUrl: './attendance-settings.component.html',
    styleUrls: ['./attendance-settings.component.css']
})
export class AttendanceSettingsComponent implements OnInit {
    public form: UntypedFormGroup;
    public submitted = false;
    public settings$: Observable<any>;
    public employees$: Observable<Employee[]>;
    public datetimeTypes$: Observable<EmployeeDatetimeType[]>;
    public displayModeOptions = [
        'DESCENDANTS_AND_SIBLINGS',
        'DESCENDANTS_AND_SIBLINGS_ONLY_WORK_TYPE',
        'DESCENDANTS_AND_SIBLINGS_NO_TIME',
        'DESCENDANTS',
        'DESCENDANTS_ONLY_WORK_TYPE',
        'DESCENDANTS_NO_TIME',
        'TEAM',
        'TEAM_ONLY_WORK_TYPE',
        'TEAM_NO_TIME',
        'WHOLE_COMPANY',
        'WHOLE_COMPANY_ONLY_WORK_TYPE',
        'WHOLE_COMPANY_NO_TIME',
    ];

    public constructor(
        private _companyService: CompanyService,
        private _fb: UntypedFormBuilder,
        private _datetimeTypesStore: DatetimesTypesStoreService,
        private _employeeService: EmployeeService
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.form.controls;
    }

    public ngOnInit(): void {
        this.form = this._fb.group({
            'company.attendance.display_mode': [DEFAULT_DISPLAY_MODE],
            'company.attendance.absence_types': [null],
            'company.attendance.absence_range': [7, Validators.min(1)],
            'company.attendance.hide_from_gantt': [null],
        });

        this.datetimeTypes$ = this._datetimeTypesStore.datetimeTypes$;
        this.employees$ = this._employeeService.getAllEmployees(false, 'select,config')
            .pipe(tap(employees => {
                this.form.patchValue({
                    'company.attendance.hide_from_gantt': employees.filter(employee => employee.hide_from_gantt).map(employee => employee.employee_ID)
                });
            }));

        this.settings$ = this._companyService.getCompanySetting()
            .pipe(
                tap(settings => {
                    this.form.patchValue({
                        'company.attendance.display_mode': settings.company_settings.attendance?.display_mode || DEFAULT_DISPLAY_MODE,
                        'company.attendance.absence_types': settings.company_settings.attendance?.absence_types || null,
                        'company.attendance.absence_range': settings.company_settings.attendance?.absence_range || 7,
                    });
                })
            );
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.form.valid) {
            return;
        }

        const value = this.form.value;

        value.settings_type = 'attendance';

        void this._companyService.saveCompanySetting(this.form.value);
    }
}
