<form
  *ngIf="settings$ | async"
  autocomplete="off"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <app-help name="settings.attendance.visibility"></app-help>

        <div class="card-header py-3">
          <div class="row">
            <div class="col-sm-6 text-left my-auto">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'settings.page_title_view_attendance_settings' | translate }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <p>{{ 'settings.attendance_display_mode_info_structure' | translate }}</p>
              <p>{{ 'settings.attendance_display_mode_info_work_type' | translate }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label">
                  <span>{{ 'settings.attendance_display_mode' | translate }}</span>
                </label>

                <ng-select
                  class="custom"
                  placeholder="{{ 'settings.attendance_display_mode_select' | translate }}"
                  formControlName="company.attendance.display_mode"
                  name="company.attendance.display_mode"
                  [clearable]="false"
                >
                  <ng-option *ngFor="let displayModeOption of displayModeOptions" [value]="displayModeOption">
                    <span>{{ 'settings.attendance_display_mode_' + displayModeOption | translate}}</span>
                  </ng-option>
                </ng-select>

                <div *ngIf="submitted && f['company.attendance.display_mode'].errors">
                  <p
                    *ngIf="f['company.attendance.display_mode'].errors.required"
                    class="text-danger validation-text"
                  >
                    <span>{{ 'angular_validation.field' | translate }} </span>
                    <span>{{ 'settings.attendance_display_mode' | translate }} </span>
                    <span>{{ 'angular_validation.required' | translate }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="control-label">
                            <span>{{ 'settings.attendance_hidden_employees_from_gantt' | translate }}</span>
                        </label>
                        <ng-select
                            class="custom"
                            formControlName="company.attendance.hide_from_gantt"
                            name="company.attendance.hide_from_gantt"
                            [clearable]="true"
                            [items]="employees$ | async"
                            bindLabel="fullname"
                            bindValue="employee_ID"
                            [multiple]="true"
                            [closeOnSelect]="false">
                        </ng-select>
                    </div>
                </div>
            </div>

          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'settings.attendance_absence_types' | translate }}</span>
                    </label>
                    <ng-select
                        class="custom"
                        formControlName="company.attendance.absence_types"
                        name="company.attendance.absence_types"
                        [clearable]="false"
                        [items]="datetimeTypes$ | async"
                        bindLabel="name"
                        bindValue="employee_datetime_type_ID"
                        [multiple]="true"
                        [closeOnSelect]="false"
                    >
                    </ng-select>
                </div>
            </div>
              <div class="col-12 text-right">
                  <small class="text-muted"> {{ 'settings.attendance_absence_types_info' | translate }}</small>
              </div>
          </div>

          <div class="row">
            <div class="col-12">
                <div class="form-group mb-1">
                    <label class="control-label" for="company.attendance.absence_range">
                        <span>{{ 'settings.attendance_absence_range' | translate }}</span>
                    </label>
                    <input class="form-control"
                           formControlName="company.attendance.absence_range"
                           id="company.attendance.absence_range"
                           name="company.attendance.absence_range"
                           onlyNumber
                           type="number">
                </div>
            </div>
              <div class="col-12 text-right">
                  <small class="text-muted"> {{ 'settings.attendance_absence_range_info' | translate }}</small>
              </div>
          </div>

          <div class="row mt-2">
            <div class="col d-flex justify-content-end">
              <button class="btn btn-success">
                <span>{{ 'global.action_save' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
